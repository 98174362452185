<template>
  <div class="main">
    <van-nav-bar
        title="通知"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
     />
    <div class="main">
      <div class="jian"></div>
      <div class="block1" style="padding: 1rem">
        <div class="title">{{data.title}}</div>
        <div class="time">{{data.updateDate}}</div>
        <div class="content" style="text-align: left">{{data.content}}</div>
      </div>

    </div>
  </div>

</template>

<script>
import { getNoticeDetail } from '@/api/get'
import Vue from 'vue';
import { NavBar,Col, Row,Icon,Cell,Popup,DatetimePicker } from 'vant';
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(DatetimePicker);
export default {
  name: "NoticeDetail",
  data(){
    return{
      data:{},
      id:0,
      showPickerDate: false,
      currentDate: new Date(),
    }},
  mounted() {
    this.id=this.$route.query.id
    this.loadData()
    //this.queue(this.$route.meta.id,this.$route.meta.title,this.$route.path)
  },
  methods:{
    loadData(){
      getNoticeDetail({id:this.id}).then(res => {
       this.data=res.data.data
      })
    },
    onClickLeft() {
      this.$router.push({ path: '/home', query: {id:1}})
    }
  }
}
</script>

<style scoped lang="css" src="./assets/index.css" />